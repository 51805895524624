import styled from "styled-components";


export const Container = styled.div` 
  width: 1330px;
  padding: 10px 130px;
 ` 

export const WrapperBox = styled.div`
  width: 100%;
  display: flex;
  justify-content:center;
  gap:25px;
`;